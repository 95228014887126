<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <ApolloQuery :query="require('@/graphql/queries/AllCandidates.gql')" :variables="{ page, perPage, filter: getCandidateFilter, sortField: sort.field, sortOrder: sort.order }" @result="doneLoadingCandidates">
    <template slot-scope="{ result: { error, data }, isLoading }">
      <div v-if="error" class="messages apollo">
        <div v-if="error" class="messages__status error">Ошибка загрузки данных. Обратитесь в тех. поддержку</div>
      </div>
      <div class="tbl tbl--divided">
        <div class="tbl__header" v-if="$store.state.contactsShowFilter">
          <div class="tbl__filter">
            <label class="tbl__filter__item">
              <v-select appendToBody
                v-model="filter.status"
                :options="statuses"
                placeholder="Статус кандидата"
              >
                <template slot="no-options">Нет результатов</template>
                <template #open-indicator="{ attributes }">
                  <div v-bind="attributes">
                    <DownIcon/>
                  </div>
                </template>
              </v-select>
            </label>
            <label class="tbl__filter__item">
              <TagsInput :tags="filter.tags ? $store.state.allTags.filter(s => filter.tags.includes(s.id)).map(item => ({value: item.id, label: item.name})) : []" @input="onTagsChanged"/>
            </label>
            <label class="tbl__filter__item">
              <v-select appendToBody
                        :options="$store.state.allUsers.map(item => ({ value: item.id, label: item.name }))"
                        placeholder="Выберите менеджера"
                        @input="onUserChange"
                        :value="filter.responsible_user_id ? { value: filter.responsible_user_id, label: $store.state.allUsers.find(s => s.id === filter.responsible_user_id).name} : null"
              >
                <template slot="no-options">Нет результатов</template>
                <template #open-indicator="{ attributes }">
                  <div v-bind="attributes">
                    <DownIcon/>
                  </div>
                </template>
              </v-select>
            </label>
            <label class="tbl__filter__item">
              <v-select appendToBody
                        placeholder="Выберите подразделения"
                        :options="$store.state.allGroups.map(item => ({ value: item.id, label: item.name }))"
                        @input="onGroupChange"
                        multiple
                        :value="filter.group_id ? $store.state.allGroups.filter(s => filter.group_id.includes(s.id)).map(item => ({ value: item.id, label: item.name })) : []"
              >
                <template slot="no-options">Нет результатов</template>
                <template #open-indicator="{ attributes }">
                  <div v-bind="attributes">
                    <DownIcon/>
                  </div>
                </template>
              </v-select>
            </label>
          </div>
          <div class="tbl__settings">
            <div class="tbl__type">
              <a :class="{ active: $store.state.data.contracts.view === 'table'}" @click.prevent="$store.state.data.contracts.view = 'table'" href="#">
                <TypeTable/>
              </a>
              <a :class="{ active: $store.state.data.contracts.view === 'kanban'}" @click.prevent="$store.state.data.contracts.view = 'kanban'" href="#">
                <TypeKanban/>
              </a>
            </div>
          </div>
        </div>
        <div class="tbl__actions" v-if="$store.state.checkedCandidates.length">
          <div class="tbl__actions__main">
            <div class="tbl__actions__count">
              <span>Выбрано: {{ $store.state.checkedCandidates.length }}</span>
            </div>
            <div class="tbl__actions__items">
              <div class="tbl__actions__item">
                <a :class="{'active': actionType === 'switchResponsible'}" @click.prevent="actionType = 'switchResponsible'" href="#">
                  <i aria-hidden="true">
                    <ResponsibleIcon/>
                  </i>
                  <span>Сменить ответственного</span>
                </a>
                <div class="table-actions__drop" v-if="actionType === 'switchResponsible'">
                  <div class="table-actions__container">
                    <v-select appendToBody :options="$store.state.allUsers.map(item => ({ value: item.id, label: item.name }))" @input="onResponsibleChange" placeholder="Выберите исполнителя" v-click-outside="vcoConfig">
                      <template slot="no-options">Нет результатов</template>
                      <template #open-indicator="{ attributes }">
                        <div v-bind="attributes">
                          <DownIcon/>
                        </div>
                      </template>
                    </v-select>
                  </div>
                </div>
              </div>
              <div class="tbl__actions__item">
                <a :class="{'active': actionType === 'switchStatus'}" @click.prevent="actionType = 'switchStatus'" href="#">
                  <i aria-hidden="true">
                    <SwitchIcon/>
                  </i>
                  <span>Сменить статус</span>
                </a>
                <div class="table-actions__drop" v-if="actionType === 'switchStatus'">
                  <div class="table-actions__container">
                    <v-select appendToBody :options="statuses" @input="onStatusChange" placeholder="Выберите статус" v-click-outside="vcoConfig">
                      <template slot="no-options">Нет результатов</template>
                      <template #open-indicator="{ attributes }">
                        <div v-bind="attributes">
                          <DownIcon/>
                        </div>
                      </template>
                    </v-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tbl__actions__rest">
            <a @click.prevent="showDeleteModal = true" class="red bold" href="#">
              <span>Удалить</span>
            </a>
          </div>
        </div>
        <mq-layout :mq="[ 'lg', 'xlg', 'xl' ]">
          <vue-good-table :columns="columns" :pagination-options="{enabled: true, perPage: perPage}" :select-options="{ selectOnCheckboxOnly: true, selectionText: 'строка выбрана', clearSelectionText: 'очистить', enabled: true }" :rows="data ? data.allCandidates : []" @on-selected-rows-change="selectedRowsChange" @on-sort-change="sortingField2" compactMode>
            <template slot="table-row" slot-scope="props">
              <router-link class="tag" :class="getStatusColor(props.row.status)" :to="{ name: 'candidate', params: { id: props.row._id } }" v-if="props.column.field === 'status'">{{ props.row.status }}</router-link>
              <router-link :to="{ name: 'candidate', params: { id: props.row._id } }" v-if="props.column.field === 'name'">{{ props.row.name }}</router-link>
              <router-link :to="{ name: 'candidate', params: { id: props.row._id } }" v-if="props.column.field === 'phone'">{{ getFirstPhone(props.row.phone) }}</router-link>
              <router-link :to="{ name: 'candidate', params: { id: props.row._id } }" v-if="props.column.field === 'email'">{{ props.row.email }}</router-link>
              <router-link :to="{ name: 'candidate', params: { id: props.row._id } }" v-if="props.column.field === 'city'">{{ props.row.city }}</router-link>
              <router-link :to="{ name: 'candidate', params: { id: props.row._id } }" v-if="props.column.field === 'responsible_user_id'">{{ props.row.responsible_user && props.row.responsible_user.name ? props.row.responsible_user.name : 'Нет менеджера' }}</router-link>
              <!--                <span v-else>{{props.formattedRow[props.column.field]}}</span>-->
            </template>
            <div slot="emptystate">Данных нет</div>
            <template slot="pagination-bottom" slot-scope="props">
              <div class="vgt-wrap__footer vgt-clearfix">
                <div class="footer__row-count vgt-pull-left">
                  <span class="footer__row-count__label">Показано</span>
                  <select @change="perPageChanged(props)" v-model="perPage" class="footer__row-count__select">
                    <option :value="10">10</option>
                    <option :value="20">20</option>
                    <option :value="50">50</option>
                    <option :value="100">100</option>
                    <option :value="200">200</option>
                  </select>
                </div>
              </div>
              <paginate :click-handler="setPage" :page-count="pageCount" :value="page + 1" prev-text="&lsaquo;" next-text="&rsaquo;" container-class="tbl__pagination vgt-pull-right">
                <span slot="prevContent">
                  <ArrowLeftIcon/>
                </span>
                <span slot="nextContent">
                  <ArrowRightIcon/>
                </span>
              </paginate>
            </template>
          </vue-good-table>
        </mq-layout>
        <mq-layout :mq="[ 'xsm', 'md' ]" class="tbl__container">
          <table>
            <thead>
            <tr>
              <th>
                <div class="tbl__cols">
                  <span :class="getSortableClass('status')" @click="sortingField('status')">Статус</span>
                  <span :class="getSortableClass('name')" @click="sortingField('name')">Наименование</span>
                </div>
              </th>
              <th>
                <div class="tbl__cols">
                  <span :class="getSortableClass('phone')" @click="sortingField('phone')">Телефон</span>
                  <span :class="getSortableClass('email')" @click="sortingField('email')">E-mail</span>
                </div>
              </th>
              <th>
                <div class="tbl__cols">
                  <span :class="getSortableClass('city')" @click="sortingField('city')">Город</span>
                  <span :class="getSortableClass('responsible_user_id')" @click="sortingField('responsible_user_id')">Ответственный</span>
                </div>
              </th>
            </tr>
            </thead>
            <tbody v-if="isLoading">
            <tr>
              <td colspan="3">Загрузка...</td>
            </tr>
            </tbody>
            <tbody v-else-if="data && data.allCandidates && data.allCandidates.length">
            <tr :key="index" v-for="(candidate, index) in data.allCandidates">
              <td>
                <div class="tbl__content__container">
                  <checkbox :class-name="$store.state.checkedCandidates.includes(candidate._id) ? 'active' : ''" v-model="$store.state.checkedCandidates" :value="candidate._id"></checkbox>
                  <div class="tbl__content">
                    <router-link class="tag" :class="getStatusColor(candidate.status)" :to="{name: 'candidate', params: {id: candidate._id }}">{{ candidate.status }}</router-link>
                    <router-link :to="{name: 'candidate', params: {id: candidate._id }}">{{ candidate.name }}</router-link>
                  </div>
                </div>
              </td>
              <td>
                <div class="tbl__content">
                  <router-link :to="{name: 'candidate', params: {id: candidate._id }}">{{ getFirstPhone(candidate.phone) }}</router-link>
                  <router-link :to="{name: 'candidate', params: {id: candidate._id }}">
                    {{ candidate.email }}
                  </router-link>
                </div>
              </td>
              <td>
                <div class="tbl__content">
                  <router-link :to="{name: 'candidate', params: {id: candidate._id }}">
                    {{ candidate.city }}
                  </router-link>
                  <router-link :to="{name: 'candidate', params: {id: candidate._id }}">
                    {{ candidate.responsible_user && candidate.responsible_user.name ? candidate.responsible_user.name : 'Нет менеджера' }}
                  </router-link>
                </div>
              </td>
            </tr>
            </tbody>
            <tbody v-else>
            <tr>
              <td colspan="5">Нет результатов</td>
            </tr>
            </tbody>
          </table>
        </mq-layout>
        <mq-layout :mq="[ 'xs', 'sm' ]" class="tbl__container tbl__mobile">
          <table>
            <tbody v-if="isLoading">
            <tr>
              <td colspan="1">Загрузка...</td>
            </tr>
            </tbody>
            <tbody v-else-if="data && data.allCandidates && data.allCandidates.length">
            <tr :key="index" v-for="(candidate, index) in data.allCandidates">
              <td>
                <div class="tbl__content__container">
                  <checkbox :class-name="$store.state.checkedCandidates.includes(candidate._id) ? 'active' : ''" v-model="$store.state.checkedCandidates" :value="candidate._id"></checkbox>
                  <div class="tbl__content">
                    <router-link class="tag" :class="getStatusColor(candidate.status)" :to="{name: 'candidate', params: {id: candidate._id }}">{{ candidate.status }}</router-link>
                    <router-link :to="{name: 'candidate', params: {id: candidate._id }}">{{ candidate.name }}</router-link>
                    <router-link :to="{name: 'candidate', params: {id: candidate._id }}">{{ getFirstPhone(candidate.phone) }}</router-link>
                    <router-link :to="{name: 'candidate', params: {id: candidate._id }}">
                      {{ candidate.email }}
                    </router-link>
                    <router-link :to="{name: 'candidate', params: {id: candidate._id }}">
                      {{ candidate.city }}
                    </router-link>
                    <router-link :to="{name: 'candidate', params: {id: candidate._id }}">
                      {{ candidate.responsible_user && candidate.responsible_user.name ? candidate.responsible_user.name : 'Нет менеджера' }}
                    </router-link>
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
            <tbody v-else>
            <tr>
              <td colspan="1">Нет результатов</td>
            </tr>
            </tbody>
          </table>
        </mq-layout>
      </div>
      <mq-layout :mq="[ 'xs', 'sm', 'xsm', 'md' ]" class="tbl__footer">
        <div class="tbl__footer__item left"></div>
        <div class="tbl__footer__item">
          <paginate :page-count="pageCount" :value="page + 1" :click-handler="setPage" prev-text="&lsaquo;" next-text="&rsaquo;" container-class="tbl__pagination vgt-pull-right">
            <span slot="prevContent">
              <ArrowLeftIcon/>
            </span>
            <span slot="nextContent">
              <ArrowRightIcon/>
            </span>
          </paginate>
        </div>
        <div class="tbl__footer__item right">
          <label class="tbl__pagination__info">
            Показано
            <span class="bold">{{ perPage }}</span>
            из
            <span class="bold">{{ allCandidatesMeta }}</span>
          </label>
        </div>
      </mq-layout>
      <LsgModal attention v-if="showDeleteModal" @close="showDeleteModal = !showDeleteModal">
        <template slot="ico">
          <AttentionIco/>
        </template>
        <template slot="body">
          <h2>Вы уверены?</h2>
          <p>После удаления данные не могут быть восстановлены</p>
        </template>
        <template slot="footer">
          <button class="button button__red" @click="removeCandidates">Удалить</button>
          <button class="button" @click="showDeleteModal = !showDeleteModal">Отмена</button>
        </template>
      </LsgModal>
    </template>
  </ApolloQuery>
</template>

<script>
import gql from 'graphql-tag'
import LsgModal from '@/components/ui/LsgModal'
import TagsInput from '@/components/ui/GraphInputs/TagsInput'
import AttentionIco from '@/components/svg/attention'
import GraphQLHelper from '@/helpers/GraphQLHelper'
import ArrowLeftIcon from '@/components/svg/arrow_left'
import ArrowRightIcon from '@/components/svg/arrow_right'
import DownIcon from '@/components/svg/DownIcon'
import ResponsibleIcon from '@/components/svg/ResponsibleIcon'
import SwitchIcon from '@/components/svg/SwitchIcon'
import UPDATE_CANDIDATES from '@/graphql/mutations/UpdateCandidates.gql'
import DELETE_CANDIDATES from '@/graphql/mutations/DeleteCandidates.gql'
import TypeTable from '@/components/svg/TypeTable'
import TypeKanban from '@/components/svg/TypeKanban'

const _graphQlHelper = new GraphQLHelper()

export default {
  components: {
    ResponsibleIcon,
    DownIcon,
    SwitchIcon,
    ArrowRightIcon,
    ArrowLeftIcon,
    TagsInput,
    LsgModal,
    AttentionIco,
    TypeKanban,
    TypeTable
  },
  data () {
    return {
      showDeleteModal: false,
      title: 'Кандидаты',
      columns: [
        {
          label: 'Статус',
          field: 'status'
        },
        {
          label: 'Наименование',
          field: 'name'
        },
        {
          label: 'Телефон',
          field: 'phone'
        },
        {
          label: 'E-mail',
          field: 'email'
        },
        {
          label: 'Город',
          field: 'city'
        },
        {
          label: 'Ответственный',
          field: 'responsible_user_id'
        }
      ],
      statuses: ['Не обработано', 'Собеседование', 'Проверка СБ', 'Финалисты', 'Оформление', 'Закрыт'],
      page: 0,
      perPage: 10,
      view: 'table',
      sort: {
        field: 'date',
        order: 'desc'
      },

      actionType: null,
      vcoConfig: {
        handler: () => {
          this.actionType = null
        },
        middleware (event) {
          let name = event.target.getAttribute('class') || ''
          return name !== null ? !name.includes('mx-') : true
        },
        events: ['dblclick', 'click'],
        isActive: true
      },
      filter: {
        q: '',
        name: '',
        phone: '',
        email: '',
        tags: [],
        responsible_user_id: null,
        group_id: [],
        status: ''
      },
      allCandidatesMeta: 0
    }
  },
  created () {
    this.page = this.$store.state.filters.contacts.page
    this.perPage = this.$store.state.filters.contacts.perPage
    this.sort = this.$store.state.filters.contacts.sort
    this.filter = this.$store.state.filters.contacts.filter
  },
  beforeRouteLeave (to, from, next) {
    this.$store.state.filters.contacts.page = this.page
    this.$store.state.filters.contacts.perPage = this.perPage
    this.$store.state.filters.contacts.sort = this.sort
    this.$store.state.filters.contacts.filter = this.filter
    next()
  },
  computed: {
    pageCount () {
      return Math.ceil(this.allCandidatesMeta / this.perPage)
    },
    getCandidateFilter () {
      let currentFilter = {}
      if (this.filter.name) currentFilter.name = this.filter.name
      if (this.filter.email) currentFilter.email = this.filter.email
      if (this.filter.status) currentFilter.status = this.filter.status
      if (this.filter.phone) currentFilter.phone = this.filter.phone
      if (this.filter.tags.length) currentFilter.tags = this.filter.tags
      if (this.filter.responsible_user_id) currentFilter.responsible_user_id = this.filter.responsible_user_id
      if (this.filter.group_id.length) currentFilter.group_id = this.filter.group_id

      return currentFilter
    },
    isTableView () {
      return this.$store.state.data.contracts.view === 'table'
    },
    isKanbanView () {
      return this.$store.state.data.contracts.view === 'kanban'
    }
  },
  apollo: {
    allCandidatesMeta () {
      return {
        query: gql`query($filter: CandidateFilter) {
          _allCandidatesMeta(filter: $filter) {
            count
          }
        }`,
        variables () {
          return {
            filter: this.getCandidateFilter
          }
        },
        update: (data) => {
          this.$nextTick(() => {
            this.$store.state.loadedPages.push({ title: this.$route.meta.title, route: this.$route.name })
          })
          return data._allCandidatesMeta.count || 0
        }
      }
    }
  },
  methods: {
    async onResponsibleChange (user) {
      if (user) {
        await this.$apollo.mutate({
          mutation: UPDATE_CANDIDATES,
          variables: {
            ids: this.$store.state.checkedCandidates,
            responsible_user_id: user.value
          }
        }).then(() => {
          this.$router.go()
        }).catch(error => {
          _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
            this.$notify({
              group: 'lsg-notify',
              text: item
            })
          })
        })
      }
    },
    async onStatusChange (status) {
      if (status) {
        await this.$apollo.mutate({
          mutation: UPDATE_CANDIDATES,
          variables: {
            ids: this.$store.state.checkedCandidates,
            status: status
          }
        }).then(() => {
          this.$router.go()
        }).catch(error => {
          _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
            this.$notify({
              group: 'lsg-notify',
              text: item
            })
          })
        })
      }
    },
    selectedRowsChange ({ selectedRows }) {
      this.$store.state.checkedCandidates = selectedRows.map(row => row._id)
    },
    perPageChanged (props) {
      props.perPageChanged({ currentPerPage: this.perPage })
    },
    removeCandidates () {
      this.$apollo.mutate({
        mutation: DELETE_CANDIDATES,
        variables: {
          ids: this.$store.state.checkedCandidates
        }
      }).then(() => {
        this.$router.go()
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
        })
      })
    },
    doneLoadingCandidates ({ data }) {
      // this.$store.commit('allCandidates', { data, isMore: false })
    },
    setPage (page) {
      this.page = page - 1
    },
    onGroupChange (e) {
      this.filter.group_id = e.map(item => item.value)
      this.setPage(1)
    },
    onUserChange (user) {
      if (user) {
        this.filter.responsible_user_id = user.value
      } else {
        this.filter.responsible_user_id = null
      }
      this.setPage(1)
    },
    onTagsChanged (tags) {
      this.filter.tags = tags.map(item => item.value)
      this.setPage(1)
    },
    sortingField2 (params) {
      this.sort.order = params[0].type
      this.sort.field = params[0].field
    },
    sortingField (columnName) {
      if (columnName === this.sort.field) this.sort.order = this.sort.order === 'asc' ? 'desc' : 'asc'
      this.sort.field = columnName
    },
    getFirstPhone (phones) {
      if (phones && phones.length) return phones[0]
      return 'Нет номера телефона'
    },
    getSortableClass (columnName) {
      let currentClass = 'lsg-table-sort'
      if (columnName === this.sort.field) currentClass += ' sort-' + this.sort.order
      return currentClass
    },
    getStatusColor (status) {
      switch (status) {
        case 'Не обработано':
          return 'tag--red'
        case 'Собеседование':
          return 'tag--orange'
        case 'Проверка СБ':
          return 'tag--blue'
        case 'Финалисты':
          return 'tag--dark-blue'
        case 'Оформление':
          return 'tag--green'
        case 'Закрыт':
          return 'tag--grey'
      }
    }
  }
}
</script>
