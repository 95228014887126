<template>
  <div class="lsg-contacts">
    <div class="main__header">
      <div class="main__header__item left">
        <mq-layout :mq="['sm', 'xsm', 'md', 'lg']" class="filter_hide">
          <span class="btn btn_ico" @click="$store.state.contactsShowFilter = !$store.state.contactsShowFilter " :class="{'disable': $store.state.contactsShowFilter }">
            <FilterIco/>
            Скрыть фильтры
          </span>
        </mq-layout>
      </div>
      <div class="main__header__item">
        <h1>{{ title }}</h1>
      </div>
      <div class="main__header__item right">
        <button class="btn" type="submit" @click="$router.push({ name: 'add_candidate' })">
          <i>
            <PlusIco/>
          </i>
          <span>Создать кандидата</span>
        </button>
      </div>
    </div>
    <div class="main__content">
      <CandidatesTable v-if="isTableView"/>
      <CandidatesKanban v-if="isKanbanView"/>
    </div>
  </div>
</template>

<script>
import PlusIco from '@/components/svg/plus'
import CandidatesTable from './components/CandidatesTable.vue'
import CandidatesKanban from './components/CandidatesKanban.vue'

export default {
  components: {
    PlusIco,
    CandidatesTable,
    CandidatesKanban
  },
  data () {
    return {
      title: 'Кандидаты',
      view: 'table',
      actionType: null,
      vcoConfig: {
        handler: () => {
          this.actionType = null
        },
        middleware (event) {
          let name = event.target.getAttribute('class') || ''
          return name !== null ? !name.includes('mx-') : true
        },
        events: ['dblclick', 'click'],
        isActive: true
      }
    }
  },
  computed: {
    isTableView () {
      return this.$store.state.data.contracts.view === 'table'
    },
    isKanbanView () {
      return this.$store.state.data.contracts.view === 'kanban'
    }
  }
}
</script>
