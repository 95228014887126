<template>
  <div class="tbl tbl--divided">
    <div class="tbl__header">
      <div class="tbl__filter">
        <label class="tbl__filter__item">
          <TagsInput @input="onTagsChanged"/>
        </label>
      </div>
      <div class="tbl__settings">
        <div class="tbl__type">
          <a :class="{ active: $store.state.data.contracts.view === 'table'}" @click.prevent="$store.state.data.contracts.view = 'table'" href="#">
            <TypeTable/>
          </a>
          <a :class="{ active: $store.state.data.contracts.view === 'kanban'}" @click.prevent="$store.state.data.contracts.view = 'kanban'" href="#">
            <TypeKanban/>
          </a>
        </div>
      </div>
    </div>
    <div class="tbl__container">
      {{blocks}}
      <kanban-board @init="init" @over="overDrag" @drag="startDrag" @dragend="endDrag" :blocks="blocks" :stages="stages" class="kanban-wrapper" id="contracts-kanban" @update-block="update">
        <div :key="stage" :slot="stage" class="contracts-kanban__heading" v-for="stage in stages">
          <h3 class="contracts-kanban__heading__title">
            <span class="contracts-kanban__heading__title__text">{{ stage }}</span>
            <span class="contracts-kanban__heading__title__count">{{ getCountCandidateOfStatus(stage) }}</span>
          </h3>
        </div>
        <div :key="block._id" :slot="block._id"  class="kanban-block" v-for="block in blocks">
          <router-link :to="{ name: 'candidate', params: { id: block._id } }" class="contracts-kanban">
            <div class="contracts-kanban__header">
              <div class="contracts-kanban__header__item">{{ block.name }}</div>
            </div>
            <div class="contracts-kanban__body">
              {{ block.email }}
            </div>
          </router-link>
        </div>
      </kanban-board>
      <scroll-loader :loader-disable="loadMore" :loader-method="nextPage">
        <div>Загрузка...</div>
      </scroll-loader>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import TypeTable from '@/components/svg/TypeTable'
import TypeKanban from '@/components/svg/TypeKanban'
import TagsInput from '@/components/ui/GraphInputs/TagsInput'
import ALL_CANDIDATES from '@/graphql/queries/AllCandidates.gql'
import { extendMoment } from 'moment-range'

const _moment = extendMoment(moment)
const Today = _moment()
const Tomorrow = _moment().add(1, 'days')
const Week = _moment().endOf('week')

export default {
  name: 'ContractsKanban',
  components: { TagsInput, TypeKanban, TypeTable },
  data: () => {
    return {
      stages: ['Не обработано', 'Собеседование', 'Проверка СБ', 'Финалисты', 'Оформление'],
      blocks: [],
      perPage: 10,
      loadMore: false,
      prevLength: 0,
      timeout: null,
      page: 0,
      dragula: null,
      over: null,
      source: null,
      count: {
        'Не обработано': 0,
        'Собеседование': 0,
        'Проверка СБ': 0,
        'Финалисты': 0,
        'Оформление': 0
      },
      filter: {
        tags: []
      }
    }
  },
  created () {
    this.loadCandidates()
  },
  watch: {
    '$store.state.loadMoreContracts' () {
      if (this.$store.state.loadMoreContracts) {
        this.page++
      }
    },
    '$store.state.data.contracts.sort2' () {
      this.filter.status_date = this.getRange(this.$store.state.data.contracts.sort2)
      this.reload()
    },
    '$store.state.data.contracts.sortDate' () {
      this.filter.status_date = this.getRange(this.$store.state.data.contracts.sort2)
      this.reload()
    }
  },
  methods: {
    init (dragula) {
      this.dragula = dragula
      dragula.containers.push(...Array.from(document.querySelectorAll('.sb li')))
    },
    overDrag (el, container) {
      this.over = null
      document.querySelectorAll('.sb__container').forEach(item => {
        return item.classList.remove('active')
      })
      if (container.classList.contains('sb__container')) {
        container.classList.add('active')
        this.over = container
      }
    },
    endDrag (block, list, source, sibling) {
      this.statusBoard = false
    },
    startDrag (el, source) {
      this.statusBoard = true
      this.source = source
      this.over = null
    },
    async update (id, block, status) {
    },
    nextPage () {
      this.page++
      this.loadCandidates()
      this.loadMore = true
    },
    loadCandidates () {
      this.stages.forEach(stage => {
        let filter = this.getCandidateFilter()
        filter.status = stage
        this.$apollo.mutate({
          mutation: ALL_CANDIDATES,
          variables: {
            filter: filter,
            page: this.page,
            perPage: this.perPage,
            sortField: 'createdAt',
            sortOrder: 'desc'
          },
          update: (store, { data }) => {
            // Блоки
            let currentBlocks = this.blocks
            this.blocks = currentBlocks.concat(data.allCandidates.filter(i => currentBlocks.indexOf(i) === -1))

            // Количество
            if (data._allCandidatesMeta && data._allCandidatesMeta.count) this.count[stage] = data._allCandidatesMeta.count
          }
        })
      })
    },
    getRange (status) {
      switch (status) {
        case 'today':
          return {
            start: _moment(Today).startOf('day').format('YYYY-MM-DDTHH:mm'),
            end: _moment(Today).startOf('day').add(1, 'days').format('YYYY-MM-DDTHH:mm')
          }
        case 'tomorrow':
          return {
            start: _moment(Tomorrow).startOf('day').format('YYYY-MM-DDTHH:mm'),
            end: _moment(Tomorrow).startOf('day').add(1, 'days').format('YYYY-MM-DDTHH:mm')
          }
        case 'week':
          return {
            start: _moment(Today).startOf('day').format('YYYY-MM-DDTHH:mm'),
            end: _moment(Week).endOf('week').add(1, 'days').format('YYYY-MM-DDTHH:mm')
          }
        case 'month':
          return {
            start: _moment(Today).startOf('month').format('YYYY-MM-DDTHH:mm'),
            end: _moment(Today).endOf('month').add(1, 'days').format('YYYY-MM-DDTHH:mm')
          }
        case 'period':
          return {
            start: _moment(this.$store.state.data.contracts.sortDate[0]).startOf('day').format('YYYY-MM-DDTHH:mm'),
            end: _moment(this.$store.state.data.contracts.sortDate[1]).endOf('day').format('YYYY-MM-DDTHH:mm')
          }
        default:
          return null
      }
    },
    getCandidateFilter () {
      let currentFilter = {}
      if (this.filter.tags.length) currentFilter.tags = this.filter.tags
      return currentFilter
    },
    reloadTimeout () {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.reload()
      }, 500)
    },
    reload () {
      this.blocks = []
      this.count = {
        'Не обработано': 0,
        'Собеседование': 0,
        'Проверка СБ': 0,
        'Финалисты': 0,
        'Оформление': 0
      }
      this.page = 0
      this.loadCandidates()
    },
    onTagsChanged (tags) {
      this.filter.tags = tags.map(item => item.value)
      this.reload()
    },
    prettifyPrice (price) {
      return Number(price).toLocaleString()
    },
    getDate (datetime) {
      return datetime ? moment(String(datetime)).format('DD.MM.YYYY') : ''
    },
    getFirstPhone (phones) {
      if (phones && phones.length) return phones[0]
      return 'Нет номера телефона'
    },
    getCountCandidateOfStatus (status) {
      if (this.count[status] !== undefined) {
        return this.count[status]
      }
    }
  }
}
</script>

<style lang="stylus">
#candidates-kanban .drag-column-header
  padding 0
</style>
